import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ActivityIndicator,
  SafeAreaView,
  SectionList,
  StatusBar,
  Modal,
  TouchableOpacity,
  TextInput,
  Alert
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import DateTimePicker from '@react-native-community/datetimepicker';
import moment from 'moment';

// === API Helper Functions & API Calls (inlined) ===

const API_BASE_URL = 'https://juwelenorogem.be/wp-json/wp/v2';
const AUTH_URL = 'https://juwelenorogem.be/wp-json/jwt-auth/v1/token';

async function getToken() {
  return await AsyncStorage.getItem('jwtToken');
}

async function saveToken(token) {
  await AsyncStorage.setItem('jwtToken', token);
}

async function reauthenticate() {
  try {
    const username = await AsyncStorage.getItem('username');
    const password = await AsyncStorage.getItem('password');

    if (!username || !password) {
      throw new Error('No credentials stored');
    }

    const response = await fetch(AUTH_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();

    if (response.ok) {
      const { token } = data;
      await saveToken(token);
      return token;
    } else {
      throw new Error(data.message || 'Reauthentication failed');
    }
  } catch (error) {
    console.error('Reauthentication failed:', error);
    throw error;
  }
}

async function fetchWithReauth(url, options = {}, requiresAuth = true) {
  let token = await getToken();
  let headers = {
    ...options.headers,
    'Content-Type': 'application/json',
  };

  if (requiresAuth && token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  let response = await fetch(url, { ...options, headers });

  if (response.status === 403 && requiresAuth) {
    try {
      token = await reauthenticate();
      headers['Authorization'] = `Bearer ${token}`;
      response = await fetch(url, { ...options, headers });
    } catch (error) {
      throw new Error('Failed to reauthenticate and retry request');
    }
  }

  return response;
}

const apiFetchAppointments = async () => {
  const timestamp = new Date().getTime();
  let allAppointments = [];
  let page = 1;
  let keepFetching = true;

  try {
    while (keepFetching) {
      const response = await fetchWithReauth(`${API_BASE_URL}/appuntamenti?per_page=100&page=${page}&_=${timestamp}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch appointments at page ${page}`);
      }
      const data = await response.json();
      console.log(`Fetched ${data.length} appointments from page ${page}`);
      allAppointments = allAppointments.concat(data);
      if (data.length < 100) {
        keepFetching = false;
      } else {
        page += 1;
      }
    }
    console.log(`Total appointments fetched: ${allAppointments.length}`);
    return allAppointments;
  } catch (error) {
    console.error('Error fetching appointments:', error);
    throw error;
  }
};

const apiAddAppointment = async (title, description, date) => {
  try {
    const response = await fetchWithReauth(`${API_BASE_URL}/appuntamenti`, {
      method: 'POST',
      body: JSON.stringify({
        title,
        content: description,
        acf: {
          attendance_date: [{ date: moment(date).format('YYYY-MM-DD') }],
        },
        status: 'publish',
      }),
    });
    if (!response.ok) {
      const responseData = await response.json();
      throw new Error(responseData.message || 'Failed to create appointment');
    }
    return await response.json();
  } catch (error) {
    console.error('Error creating appointment:', error);
    throw error;
  }
};

// === CalendarScreen Component ===

const CalendarScreen = () => {
  const [eventsData, setEventsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newDate, setNewDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    loadAppointments();
  }, []);

  const loadAppointments = async () => {
    try {
      setLoading(true);
      const appointmentsData = await apiFetchAppointments();
      console.log(`Appointments data received:`, appointmentsData);
      const appointmentsArray = Array.isArray(appointmentsData) ? appointmentsData : [];
      const combinedEventsData = combineEventsData(appointmentsArray);
      setEventsData(combinedEventsData);
      setLoading(false);
    } catch (error) {
      console.error('Error loading appointments:', error);
      setLoading(false);
    }
  };

  const combineEventsData = (appointmentsData) => {
    const combinedEvents = [];
    const currentDate = moment().startOf('day');
    const twoMonthsLater = moment().add(2, 'months').endOf('day');

    const parseAttendanceDate = (dateObject) => {
      if (!dateObject || typeof dateObject !== 'object' || !dateObject.date) {
        console.error('Invalid date object:', dateObject);
        return null;
      }
      const dateStr = dateObject.date;
      let parsedDate = moment(dateStr, 'YYYYMMDD');
      if (!parsedDate.isValid()) {
        parsedDate = moment(dateStr, 'YYYY-MM-DD');
      }
      if (!parsedDate.isValid()) {
        console.warn(`Unrecognized date format for event ID ${event?.id || 'unknown'}:`, dateStr);
        console.error(`Invalid date format: ${dateStr}`);
        return null;
      }
      return parsedDate;
    };

    appointmentsData.forEach((event) => {
      const attendanceDates = event?.acf?.attendance_date;
      if (!attendanceDates) {
        console.warn(`Missing attendance_date for event ID ${event.id}`);
      } else {
        if (Array.isArray(attendanceDates)) {
          attendanceDates.forEach((dateObject) => {
            const eventDate = parseAttendanceDate(dateObject);
            if (eventDate && eventDate.isBetween(currentDate, twoMonthsLater, 'days', '[]')) {
              combinedEvents.push(createEventObject(event, eventDate));
            }
          });
        } else if (typeof attendanceDates === 'object') {
          const eventDate = parseAttendanceDate(attendanceDates);
          if (eventDate && eventDate.isBetween(currentDate, twoMonthsLater, 'days', '[]')) {
            combinedEvents.push(createEventObject(event, eventDate));
          }
        }
      }
    });

    const groupedEvents = combinedEvents.reduce((acc, event) => {
      const date = event.date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(event);
      return acc;
    }, {});

    return Object.keys(groupedEvents)
      .map((date) => ({
        title: date,
        data: groupedEvents[date],
      }))
      .sort((a, b) => new Date(a.title) - new Date(b.title));
  };

  const createEventObject = (event, eventDate) => {
    return {
      id: event.id,
      name: event.title?.rendered || 'Untitled Appointment',
      content: event.content?.rendered || '',
      type: 'appointment',
      date: eventDate.format('YYYY-MM-DD'),
    };
  };

  const renderItem = ({ item }) => (
    <View style={styles.item}>
      <Text style={styles.itemName}>{item.name}</Text>
      {item.content ? <Text style={styles.itemContent}>{stripHtmlTags(item.content)}</Text> : null}
    </View>
  );

  const stripHtmlTags = (html) => html.replace(/<[^>]+>/g, '');

  const handleSaveAppointment = async () => {
    try {
      setSaving(true);
      await apiAddAppointment(newTitle, newDescription, newDate);
      await loadAppointments();
      setModalVisible(false);
      setNewTitle('');
      setNewDescription('');
      setNewDate(new Date());
    } catch (error) {
      console.error('Error adding appointment:', error);
      Alert.alert('Error', 'Failed to add appointment');
    } finally {
      setSaving(false);
    }
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <StatusBar backgroundColor="#000" barStyle="light-content" />
      <View style={styles.container}>
        <Text style={styles.title}>Appointments Calendar</Text>
        {loading ? (
          <View style={styles.loadingContainer}>
            <Text style={{ color: "white", marginBottom: 20, fontSize: 28 }}>
              Loading Appointments...
            </Text>
            <ActivityIndicator size="large" color="black" />
          </View>
        ) : (
          <SectionList
            sections={eventsData}
            renderItem={renderItem}
            keyExtractor={(item) => item.id.toString()}
            renderSectionHeader={({ section: { title } }) => (
              <View style={styles.sectionHeader}>
                <Text style={styles.sectionTitle}>
                  {moment(title).format('MMMM Do YYYY')}
                </Text>
                <View style={styles.sectionSeparator} />
              </View>
            )}
            ListEmptyComponent={<Text style={styles.emptyText}>No Appointments</Text>}
          />
        )}

        {/* Floating '+' Button */}
        <TouchableOpacity style={styles.addButton} onPress={() => setModalVisible(true)}>
          <Text style={styles.addButtonText}>+</Text>
        </TouchableOpacity>

        {/* Modal for Adding Appointment */}
        <Modal
          visible={modalVisible}
          animationType="slide"
          transparent={true}
          onRequestClose={() => setModalVisible(false)}
        >
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>Add Appointment</Text>
              <TextInput
                style={styles.input}
                placeholder="Title"
                value={newTitle}
                onChangeText={setNewTitle}
              />
              <TextInput
                style={[styles.input, styles.textarea]}
                placeholder="Description"
                value={newDescription}
                onChangeText={setNewDescription}
                multiline
              />
              <input
                type="date"
                value={moment(newDate).format('YYYY-MM-DD')}
                onChange={(e) => setNewDate(new Date(e.target.value))}
                style={styles.dateInput}
              />
              <TouchableOpacity style={styles.saveButton} onPress={handleSaveAppointment} disabled={saving}>
                {saving ? <ActivityIndicator color="black" /> : <Text style={styles.saveButtonText}>Save Appointment</Text>}
              </TouchableOpacity>
              <TouchableOpacity style={styles.cancelButton} onPress={() => setModalVisible(false)}>
                <Text style={styles.cancelButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    width: 800,
    alignSelf: 'center',
  },
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  title: {
    fontSize: 30,
    alignSelf: 'center',
    marginVertical: 20,
    color: 'black',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    flexDirection: 'column',
    backgroundColor: '#eee',
    borderRadius: 5,
    padding: 15,
    marginVertical: 5,
    marginHorizontal: 20,
  },
  itemName: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
  },
  itemContent: {
    fontSize: 16,
    color: 'black',
    marginTop: 5,
  },
  emptyText: {
    color: 'white',
    textAlign: 'center',
    marginTop: 20,
    fontSize: 18,
  },
  sectionHeader: {
    backgroundColor: '#fff',
    paddingVertical: 15,
    paddingHorizontal: 20,
  },
  sectionTitle: {
    fontSize: 24,
    color: 'black',
  },
  sectionSeparator: {
    height: 1,
    backgroundColor: '#444',
    marginVertical: 10,
  },
  addButton: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    backgroundColor: '#007BFF',
    width: 60,
    height: 60,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 5,
  },
  addButtonText: {
    color: 'white',
    fontSize: 30,
    fontWeight: 'bold',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    marginHorizontal: 20,
    padding: 20,
    borderRadius: 10,
  },
  modalTitle: {
    fontSize: 24,
    marginBottom: 10,
  },
  input: {
    backgroundColor: '#eee',
    padding: 10,
    marginBottom: 10,
    borderRadius: 5,
  },
  textarea: {
    height: 100,
    textAlignVertical: 'top',
  },
  dateButtonText: {
    fontSize: 18,
    marginBottom: 10,
  },
  saveButton: {
    backgroundColor: '#007BFF',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    marginBottom: 10,
  },
  saveButtonText: {
    color: 'white',
    fontSize: 18,
  },
  cancelButton: {
    backgroundColor: 'gray',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
  },
  cancelButtonText: {
    color: 'white',
    fontSize: 18,
  },
});

export default CalendarScreen;