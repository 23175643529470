import React from 'react';
import { ScrollView, StyleSheet, View, Image, TouchableOpacity, Text, Alert } from 'react-native';


const RingBuilder = ({ navigation }) => {



  return (
    <View style={styles.container}>
      <Text style={styles.title}>Select The Model</Text>

      <ScrollView horizontal style={styles.scroll} contentContainerStyle={styles.scrollContent}>
        
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('ClassicThin')}>          
          <Image source={require('../assets/classic_thin.jpg')} style={styles.buttonImage} />
          <Text style={styles.buttonText}>Classic Thin</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('TaperedBand')}>
          <Image source={require('../assets/tapered.jpg')} style={styles.buttonImage} />
          <Text style={styles.buttonText}>Tapered Band</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('TwistShank')}>
          <Image source={require('../assets/twist.jpg')} style={styles.buttonImage} />
          <Text style={styles.buttonText}>Twist Shank Band</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('SixProngs')}>
          <Image source={require('../assets/six.jpg')} style={styles.buttonImage} />
          <Text style={styles.buttonText}>Six Prongs</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('IntertwinedBand')}>
          <Image source={require('../assets/intertwined.jpg')} style={styles.buttonImage} />
          <Text style={styles.buttonText}>Intertwined Band</Text>
        </TouchableOpacity>

      </ScrollView>

    
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F5F5F5',
  },
  title: {
    fontSize: 24,
    marginVertical: 20,
    color: 'black',
    fontFamily: 'Gilda', // Make sure you use the custom font here as well
marginBottom:40
  },
  scroll: {
    flexGrow: 0,
  },
  scrollContent: {
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  button: {
    alignItems: 'center',
    marginHorizontal: 10,
    padding: 20,
    backgroundColor: '#fff',

    borderRadius: 5,
  },
  buttonImage: {
    width: 300,
    height: 300,
    resizeMode: 'contain',
    marginBottom: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: 'black',
    fontSize: 20,
    fontFamily: 'Gilda', // Make sure you use the custom font here as well

  },

});

export default RingBuilder;
