import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, FlatList, Image, ActivityIndicator } from 'react-native';

const proxyUrl = 'https://stripe-server-node-lbl0p.kinsta.app/proxy?url=';
const apiUrl = 'https://juwelenorogem.be/en/wp-json/wc/v3/products';
const consumerKey = process.env.EXPO_PUBLIC_CONSUMER_KEY;
const consumerSecret = process.env.EXPO_PUBLIC_CONSUMER_SECRET;

const tagPriority = {
  5527: '18K White Gold',
  5528: '18K Yellow Gold',
};

const TaperedBand = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async (page = 1, allProducts = []) => {
    const timestamp = new Date().getTime();
    const url = `${proxyUrl}${encodeURIComponent(apiUrl + `?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&tag=5527,5528&per_page=100&page=${page}&_=${timestamp}`)}`;

    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP status ${response.status}`);

      const data = await response.json();
      const updatedProducts = [...allProducts, ...data];

      if (data.length === 100) {
        fetchProducts(page + 1, updatedProducts); // Continue fetching if more pages exist
      } else {
        // Sort by tag priority first, then by price
        const sortedProducts = updatedProducts.sort((a, b) => {
          const tagA = a.tags.find((tag) => tagPriority[tag.id])?.id || 9999;
          const tagB = b.tags.find((tag) => tagPriority[tag.id])?.id || 9999;

          if (tagA !== tagB) {
            return tagA - tagB;
          }
          return parseFloat(a.price) - parseFloat(b.price);
        });

        setProducts(sortedProducts);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setIsLoading(false);
    }
  };

  const renderItem = ({ item }) => {
    const tag = item.tags.find((t) => tagPriority[t.id]);
    const goldType = tag ? tagPriority[tag.id] : 'Unknown';
    const availability = item.stock_quantity > 0 ? 'READY TO SHIP' : 'ON BACKORDER';
    const availabilityStyle = item.stock_quantity > 0 ? styles.readyToShip : styles.onBackorder;

    return (
      <View style={styles.productContainer}>
        <View style={[styles.availabilityBanner, availabilityStyle]}>
          <Text style={styles.availabilityText}>{availability}</Text>
        </View>
        <Image source={{ uri: item.images[0]?.src }} style={styles.image} />
        <Text style={styles.goldType}>{goldType}</Text>
        <Text style={styles.productName}>{item.name}</Text>
        <Text style={styles.productPrice}>€{item.price}</Text>
      </View>
    );
  };

  if (isLoading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Tapered Band Rings</Text>
      <FlatList
        data={products}
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderItem}
        numColumns={4} // 4-column grid
        columnWrapperStyle={{ justifyContent: 'flex-start', gap: 10 }}      />
      
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  row: {
    justifyContent: 'space-between',
  },
  productContainer: {
    width: '23%', // 4-column grid
    marginBottom: 20,
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 10,
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
  image: {
    width: 150,
    height: 150,
    resizeMode: 'contain',
    marginBottom: 10,
  },
  goldType: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#888',
    marginBottom: 5,
  },
  productName: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  productPrice: {
    fontSize: 14,
    color: '#333',
  },
  availabilityBanner: {
    position: 'absolute',
    top: 10,
    left: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  availabilityText: {
    color: '#fff',
    fontSize: 12,
    fontWeight: 'bold',
  },
  readyToShip: {
    backgroundColor: '#d9534f', // Red for "READY TO SHIP"
  },
  onBackorder: {
    backgroundColor: '#f0ad4e', // Yellow for "ON BACKORDER"
  },
});

export default TaperedBand;